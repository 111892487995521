@import '../variables';

.tabBackground {
  background-color: $grey-5!important;
  border-color: $grey-5!important;
}

.tabDialog {
  width: 400px;
}
.align-button-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.hover-username:hover, .hover-email:hover, .hover-actions:hover, .hover-permission:hover {
  cursor: pointer;
  background-color: #f1e0d7;
  border-radius: 50px;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.hover-username, .hover-email, .hover-actions, .hover-permission {
  border: 2px solid transparent;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  box-sizing: border-box;
}

.hover-username:hover, .hover-email:hover, .hover-actions:hover, .hover-permission:hover {
  border-color: #f0d4c6;
}

.small-text {
  color: $grey-darken-1;
  font-size: 0.875rem;
}

.checkbox-margin {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 8px;
}

.center-checkbox {
  display: flex;
  justify-content: center;
}

.snackbar-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 12px;
}

.text-right {
  text-align: right;
}
.align-right {
  margin-right: 16px;
}

.pa-4 {
  padding-right: 24px
}

.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}
