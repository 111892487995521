

.video-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-wrapper.drag {
  cursor: grabbing;
}

.video-wrapper.grab {
  cursor: grab;
}

.video-wrapper.hideControl{
  display:none;
}

