

.btn {
  z-index: 1;
}
.roundedImg {
  border-radius: 4px 4px 0 0;
}
.ai-studio-text {
  color: #F26924;
  font-style: italic;
  margin-right: 4px;
}
