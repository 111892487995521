
@import "../css/variables.scss";

.subHeader {
  color: $grey-darken-1;
}

.title {
    background-color: $blue-title-2;
    padding: 16px !important;
    color: #FFFFFF;
  }

  .padNav {
    margin: 0 300px 0 0;
  }

  .sidebar-toggle {
  position: absolute;
  top: 10%;
  right:  0.625em;
  transform: translateY(-50%);
  z-index: 200;
}

@media screen and (max-width: 800px) {
  .sidebar-toggle {
    top: 10%;
    right:  0.625em;
    transform: translateY(-50%);
    z-index: 200;
  }
}

