
// @import "../css/components/_container.scss";
@import "../css/variables.scss";
.header {
color: $grey-darken-2;
}

.subHeader {
  color: $grey-darken-1;
}

.breadcrumbLink{
  cursor: pointer;
  color: var(--v-primary-base);
}
