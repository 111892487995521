
@import "../../css/variables";

.selected {
  border: 4px solid $orange-text;
  outline: 4px solid $orange-text;
  outline-offset: -4px;
  max-width: 175px;
}
.sidebar-toggle {
  position: absolute;
  top: 10%;
  right:  0.625em;
  transform: translateY(-50%);
  z-index: 200;
}

@media screen and (max-width: 800px) {
  .sidebar-toggle {
    top: 10%;
    right:  0.625em;
    transform: translateY(-50%);
    z-index: 200;
  }
}
