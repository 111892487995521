
.forgot-password {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 26em;
  min-width: 18em;

  padding: 0;
  margin: 0 auto;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
    }

    &--actions {
      justify-content: center;
    }

    &--support-email {
      display: flex;
      justify-content: center;
      color: grey;
      font-size: 0.8em;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: 360px) {
  .forgot-password {
    &__container {
      &--support-email {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
