

@import '../../css/variables';
.roundedImg {
  border-radius: 4px 4px 0 0;
}

.previewFallback {
  background-color: #f5f5f5;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 128px;
  border-radius: 4px 4px 0 0;
}

.cardLink {
  text-decoration: none;
}

.menuTriggerNoPreview {
  color: $gray-text !important;
}
