
@import '../../css/variables';

.title {
  background-color: $blue-title-2;
  color: #FFFFFF;
}

.form {
  padding: 0px 20px;
}
