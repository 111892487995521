
@import "../css/variables";

.login {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 26em;
  min-width: 18em;

  padding: 0;
  margin: 0 auto;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
    }

    &--actions {
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

@media screen and (max-width: 360px) {
  .login{
    &__container {
      &--actions {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

