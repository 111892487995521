
@import '../../css/components/_table.scss';
@import '../../css/components/_settingsTab';

.title {
  background-color: $blue-title;
}

.iconVisible {
  margin-top: 12px;
  display: block;
  float: left;
}
