
  @import '../../css/variables';

.image-editor {
  margin: 0;
  padding: 0;

  &__menu {
    display: flex;
    justify-content: space-between;
  }

  &__right-menu {
    display: flex;
  }

  &__viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

  .btn-top-menu-size {
    min-height: 48px;
  }

  .title {
    background-color: $blue-title;
  }

  .btnGroup {
    margin: 4px;
    padding: 0 12px;
  }

  .divBackground {
    background-color: #ffffff;
  }
