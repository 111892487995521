
@import '../../css/variables';

.lowerHeader {
  white-space: nowrap;
}

.root {
  background-color: var(--v-greyAlt3-base);
}
