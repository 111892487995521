
@import "../../css/variables";

.root {
  margin-top: 0px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

