
  @import '../../css/variables';

  .title {
    background-color: $blue-title;
  }
  .sidebar-toggle {
  position: absolute;
  top: 10%;
  right:  0.625em;
  transform: translateY(-50%);
  z-index: 200;
}

@media screen and (max-width: 800px) {
  .sidebar-toggle {
    top: 10%;
    right:  0.625em;
    transform: translateY(-50%);
    z-index: 200;
  }
}
