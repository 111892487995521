
@import '../../css/variables';

.title {
  background-color: $blue-title-2;
  color: #FFFFFF;
}

.buttons {
  position: absolute;
  right: 16px;
  top: 16px;
}

.top {
  padding: 20px 40px 0;
}

.detailsWrapper {
  background-color: $grey-3;
  padding: 0 40px;
}

.exportWrapper {
  padding: 0 40px;
}

.advancedItemLabel {
  color: $gray-text;
}
.advancedItem {
  font-weight: bold;
}

