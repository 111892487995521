
@import "../css/variables.scss";
.ai-analysis {
  .ai-container__breadcrumb {
    margin: 0;
    padding: 0;
    width: 80%;
  }

  &__fullscreen {
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__loading {
    display: flex;
    flex-direction: column;
    width: 400px;
    color: $grey-darken-1;
    background-color: transparent;
    border: 2px solid $blue-title-2;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    padding: 5px 10px;

    &--icon {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      gap: 15px;
    }

    &--text {
      min-height: 1em;
      color: $grey-darken-1;
      font-size: 1rem;
    }

    &--message {
      list-style: none;
    }
  }
}

.sidebar-fullscreen {
  &__drawer {
    max-height: 100% !important;
  }

  &__drawer-fullscreen {
    top: 0 !important;
  }

  &__toggle {
    position: absolute;
    top: 6%;
    right: -1em;
    transform: translateY(-50%);
    z-index: 200;
    background-color: #EEEEEE !important;
  }
}

.subHeader {
  color: $grey-darken-1;
}

.title {
  background-color: $blue-title-2;
  padding: 16px !important;
  color: #FFFFFF;
}

.padNav {
  margin: 0 300px 0 0;
}

@media screen and (max-width: 1263px) {
  .sidebar-fullscreen {
    &__toggle {
      position: absolute;
      right: 13em;
    }
  }
}

.label-value-pair {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.scan-details h2 {
  margin-top: 15px;
}

.scan-details-item {
  display: flex;
  align-items: center;
  padding: 3px 15px;
  gap: 10px;

  &.selected {
    background-color: rgb(121, 167, 246);
    color: white;
  }
}

.scan-detail-large {
  width: 200px;
  height: 200px;
  display: block;
}

.scan-detail-placeholder {
  background-color: #ccc;
  border: 2px dotted #999;
}

.detail-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 12px 20px 20px 20px;
  border-radius: 8px;
  width: 240px;
}

.fullscreen-btn {
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 1000;
}

.not-fullscreen-btn {
  position: relative;
  top: 6rem;
  z-index: 1000;
}
