
.image-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;

  &__image-info {
    padding-top: 20px;
    display: contents;
  }

  .analysis-details {
    position: absolute;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 1000;
  }
}

.image-container>div:only-child {
  grid-column: 1 / -1;
}

@media (max-width: 768px) {
  .image-container {
    grid-template-columns: 1fr;
  }
}

.page-error {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-message {
    position: absolute;
    bottom: 20px;
    left: 10px;
    background-color: #002a4a;
    color: black;
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  strong {
    display: inline;
    font-weight: 500;
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-color: #99b83c;
    border-radius: 50%;
  }
  .checkmark {
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
  .fullscreen-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  .text-container {
      background-color: white;
      border-radius: 8px;
      padding: 5px 10px;
  }
  .fullscreen-image {
    width: 600px;
    height: 600px;
    object-fit: contain;
  }
}

.capture-link {
  color: #007BFF;
  text-decoration: underline;

  &:hover {
    color: darken(#007BFF, 10%);
  }
}

