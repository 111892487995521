
.account-item {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.v-list {
  padding: 0px;
}

.v-menu__content {
  margin-left: -25px;
}
.userHeaderAvatar{
  object-fit: contain;
  width: 80px;
  height: 80px;
}
