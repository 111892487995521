
.fullscreen-viewer {
  position: relative;
  &__editor-menu {
    display: flex;
    justify-content: space-between;

    &--right-menu {
      display: flex;
    }
  }
  &__pages-detail-viewer {
    display: flex;
    justify-content: center;
  }

  &__pages{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 0;
  }

  &__controls-fixed {
    position: absolute;
    bottom: 5rem;
    right: 45%;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--zoom {
      display: flex;
    }
  }

  &__preview-lesion {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 360px;
    height: 360px;
    color: #999999;
    border: 1px solid #E0E0E0;
  }
}
