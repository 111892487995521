
@import "../css/variables.scss";
.logoutContainer {
  width: 500px;
  margin: 200px auto;
  text-align: center;
}

.subHeader {
  color: $grey-darken-1;
}
